<template>
  <b-card class="rounded-0">
    <b-row>
      <b-col cols="21" md="4" lg="4" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start">
          <b-avatar :src="avatar" :text="avatarText(uye.ad)" :variant="`light-success`" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">{{ uye.ad }} {{ uye.soyad }}</h4>
              <span class="card-text">{{ uye.e_mail }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button squared @click="handlerUpdate(uye)" variant="primary"> Düzenle </b-button>
              <b-button squared variant="outline-danger" class="ml-1" @click="handlerRemove(uye.k_no)"> Sil </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-success" rounded> G </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ balance.giris | cur }}</h5>
              <small>Giriş Bakiye</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-danger" rounded> C </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ balance.cikis | cur }}</h5>
              <small>Çıkış Bakiye</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="EyeIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ balance.kalan | cur }}</h5>
              <small>Kalan Bakiye</small>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Şehir</span>
            </th>
            <td class="pb-50">
              {{ uye.sehir }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Ülke</span>
            </th>
            <td class="pb-50">
              {{ uye.ulke }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Posta Kodu</span>
            </th>
            <td class="pb-50">
              {{ uye.posta_kodu }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">Adres</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ uye.adres }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Kayit No</span>
            </th>
            <td class="pb-50">
              {{ uye.k_no }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Kayit Tarihi</span>
            </th>
            <td class="pb-50">
              {{ uye.save_date | momentFull }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefon</span>
            </th>
            <td class="pb-50">
              {{ uye.telefon }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">Varsayilan Kur</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ uye.kur_turu }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon icon="LockIcon" class="mr-75" />
              <span class="font-weight-bold">Statu</span>
            </th>
            <td>
              <b-badge v-if="uye.statu === true" variant="light-success" class="p-1">
                <feather-icon icon="UnlockIcon" class="mr-50" />
                Aktif
              </b-badge>
              <b-badge v-else variant="light-danger" class="p-1">
                <feather-icon icon="LockIcon" class="mr-50" />
                Pasif
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <uye-tanimla v-model="openModal" :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </b-card>
</template>

<script>
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import UyeTanimla from './../uyeTanimla.vue';
export default {
  components: {
    UyeTanimla,
  },
  props: {
    uyeBilgi: {
      type: [Object, Array],
      required: true,
      default: {},
    },
  },
  data: () => ({
    uye: {},
    closeModal: false,
    openModal: false,
    updateData: {},
  }),
  computed: {
    avatar() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      if (Object.keys(this.uye).length > 1) {
        if (this.uye.logo == 'default.png') {
          return '';
        } else {
          return `${base_url}uploads/uyeprofil/${this.uye.profil}`;
        }
      }
    },
    balance() {
      let balance = {
        giris: 0.0,
        cikis: 0.0,
        kalan: 0.0,
      };
      // const hareketler = this.$store.getters.getFirmaHareketler;
      // hareketler.map((value) => {
      //   if (value.gc_kodu == 'C') {
      //     balance.cikis += value.tutar;
      //   } else {
      //     balance.giris += value.tutar;
      //   }
      //   balance.kalan = balance.giris - balance.cikis;
      // });
      return balance;
    },
  },
  methods: {
    avatarText(value) {
      if (!value) return '';
      const name = value.slice(0, 1);
      return name;
    },
    handlerUpdate(task) {
      if (task) {
        this.updateData = task;
        this.openModal = true;
      }
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Bu şirkete ait bütün kayitlar Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('uyeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Ekleme başarılı.`,
                },
              });
              router.push({ name: 'uyeler' });
            }
          });
        }
      });
    },
  },
  watch: {
    // closeModal(val) {
    //   if (val == false) {
    //     this.openModal = false;
    //     this.updateData = {};
    //   }
    // },
    uyeBilgi: {
      handler(val) {
        this.uye = val;
      },
      immediate: true,
    },
    // getUyeData: {
    //   handler(val) {
    //     this.firma = val;
    //   },
    // },
  },
};
</script>

<style></style>
